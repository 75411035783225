<template>
  <color-text-btn
    v-if="Object.keys(data.referSize || {}).length > 0 && data.sizeCheckList"
    type="info"
    size="mini"
    class="filter-item line menu-btn-item"
    @click="check"
  >
    <span>刀版图校验</span>
  </color-text-btn>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sizeStatus: {}
    }
  },
  mounted() {
    const { sizeList } = this.data
    ;(sizeList || []).map(({ sizeName, status }) => {
      this.sizeStatus[`${sizeName}`] = status
    })
  },
  methods: {
    async check() {
      // console.log(this.$store)
      this.$store.commit('verifyKnife/SET_KNIFE', this.data.sizeList)
      // this.$router.push(`/product/maintain/checkReference?protoId=${this.data.id}`)
      this.sizeStatus.protoId = this.data.referSizeId
      this.$router.push({
        path: '/product/maintain/checkReference',
        query: this.sizeStatus
      })
    }
  }
}
</script>

<style></style>
